<template>
  
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <!-- pin modal -->
        <div>
          <b-modal v-model="pinModal" title="Enter PIN">
            <b-input v-model="pin" type="number" :formatter="maxlength" placeholder="PIN" required></b-input>
            <template #modal-footer>
              <b-button variant="primary" @click="confirmPIN">Enter</b-button>
            </template>
          </b-modal>
          <!-- Scan Barcode Modal-->
        <b-modal v-model="modalScan" title="Scan Barcode" @close="clearBarcode">
          <b-container>
            <b-row>
              <b-col cols="12" v-if="!startScan">
                <p>Instructions</p>

                <ul style="list-style: decimal; padding: 0 0 0 10px;">
                  <li>Click Start</li>
                  <li>
                    Please look for barcode
                  </li>
                  <li>if the barcode is valid you can see the delivery order number below</li>
                  <li>Click save to continue</li>
                  <li>Enter the PIN to login</li>
                </ul>

                <div
                  class="my-3"
                  style="display: flex; justify-content: center"
                >
                  <b-button variant="success" @click="startScan = true"
                    >Start</b-button
                  >
                </div>
              </b-col>

              <b-col cols="12" v-if="startScan">

                <zxing-scanner @decode="(code) => (deliveryOrder = code)" />

                <b-form-input
                  v-on:keyup.enter="scan"
                  v-model="deliveryOrder"
                  type="number"
                  placeholder="Item Barcode"
                  maxlength="14"
                  class="my-1 verifyButton"
                  disabled
                  style="text-color: #ffffff"
                ></b-form-input>

                <div style="display: flex; justify-content: center">
                  <b-button
                    @click="scan"
                    :disabled="deliveryOrder === null"
                    variant="primary"
                  >
                    Save
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>

          <template #modal-footer>
            <div
              class="w-100 d-flex justify-content-center align-items-center"
            ></div>
          </template>
        </b-modal>
        </div>
        <!-- Brand logo-->
        <b-link class="brand-logo hidden">
          <h2 class="brand-text text-primary ml-1">
            Phire Studio
          </h2>
        </b-link>
        <!-- /Brand logo-->
  
        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->
  
        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <img :src="daikinLogo" style="width: 100%; height: auto; margin: 0 0 15px 0"/>
  
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              QR Login
            </b-card-title>
  
            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <!-- Scan SAP DO -->
                <b-form-group
                  label="Scan Delivery Order"
                  label-for="login-qr"
                >
                  <b-form-input
                    id="login-qr"
                    v-model="deliveryOrder"
                    name="login-qr"
                    placeholder="SAP DO"
                  />
                </b-form-group>
  
                <b-button
                  type="submit"
                  variant="success"
                  block
                  @click="scan"
                >
               
  
                  Scan
                </b-button>
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="modalScan = true"
                  
                >
                  Open Camera
                </b-button>
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :to="{ name: 'login'}"
                >
               
  
                  Login With Email
                </b-button>
              </b-form>
            </validation-observer>
  
            <!-- <b-card-text class="text-center mt-2">
              <span>New on our platform? </span>
              <b-link @click="registerUser">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text> -->
  
            <!-- divider -->
            <div class="divider my-2">
              <!-- <div class="divider-text">
                or
              </div> -->
            </div>
  
          </b-col>
        </b-col>
      <!-- /Login-->
      </b-row>
    </div>
  </template>
  
  <script>
  /* eslint-disable global-require */
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, email } from '@validations'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import store from '@/store/index'
  import ZxingVue from "../../components/ZxingEdit.vue";
  import { mapState } from 'vuex';
  // import { viewAccessLogin } from '@/utils/utils'
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      "zxing-scanner": ZxingVue,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        status: '',
        pin:'',
        deliveryOrder: '',
        pinModal: false,
        modalScan: false,
        barcode: null,
        startScan: false,
        barcodeImage: require("@/assets/images/drcc/barcode.png"),
        sideImg: require('@/assets/images/pages/login-v2.svg'),
        // validation rulesimport store from '@/store/index'
        required,
        email,
        daikinLogo: require('@/assets/images/drcc/daikin-logo.png')
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
          return this.sideImg
        }
        return this.sideImg
      },
    },
    methods: {
      scan() {
        const foundDelivery = this.$store.getters["delivery/getDO"].find(
          delivery => delivery.sapDo === this.deliveryOrder
        );
        if (foundDelivery) {
          if (foundDelivery.status !== "Delivery in Progress") {
            this.modalScan =false;
            this.$bvToast.toast("This DO is not ready for delivery", {
              title: "Error",
              variant: "danger",
              solid: true,
            });
            
          } else {
            this.modalScan =false;
            this.pinModal = true;
          }
        } else {
          this.modalScan =false;
          this.$bvToast.toast("Delivery Order is Not Exists", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      },
      clearBarcode() {
        this.deliveryOrder = null;
      },

      maxlength(e){
        return String(e).substring(0,4);
      },
      confirmPIN() {
        // Validate the entered PIN here, for example:
        if (this.pin.length !== 4) {
          this.$bvToast.toast("Incorrect PIN", {
            title: "Warning",
            variant: "danger",
            solid: true,
          });
          return;
        }
        const foundDelivery = this.$store.getters["delivery/getDO"].find(
          delivery => delivery.sapDo === this.deliveryOrder
        );
        // Check if the entered PIN matches the delivery PIN
        if (this.pin == foundDelivery.pin) {
          // If the PIN is valid, navigate to the detail delivery page
          this.$router.push({
            name: 'warehouse-detail-delivery-qr',
            params: { id: foundDelivery._id, from: "qr" },
            query: { pin: this.pin }
          });

        } else {
          this.$bvToast.toast("Incorrect PIN", {
            title: "Warning",
            variant: "danger",
            solid: true,
          });
          return;
        }
      
        // Close the modal
        this.pinModal = false;
      },
      listToBeReturned() {
        //V2  of listItemScan
        var returnData = this.doData.DO_details[this.selectIndex];
        let scannedBarcode = returnData.scannedBarcode.map((x) => {
          return x.itemUsed;
        }); //list of all scanned barcode
        let returnBarcode = returnData.returnBarcode.map((x) => {
          return x.item;
        }); //list of all returned barcode

        //finds barcode in returnBarcode that is still in scannedBarcode
        let toBeUnscanned = scannedBarcode.filter((value) => {
          return returnBarcode.includes(value);
        });

        console.log("toBeUnscanned", toBeUnscanned);
        return toBeUnscanned;
      },
    },
    mounted() {
      /**
       * this.$store.dispatch("delivery/getDeliveryOrder")
       */
      
    }
  }
  </script>
  
  <style lang="scss">
  </style>
  